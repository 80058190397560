@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

.toast-container {
   position: fixed;
   height: 5rem;
   width: fit-content;
   bottom: 2rem;
   right: 2rem;
   overflow: hidden;
}

.custom-scrollbar::-webkit-scrollbar {
   height: 8px;
}

.custom-scrollbar::-webkit-scrollbar-button {
   display: none;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
   background-color: rgb(67 56 202 / 0.9);
}

.custom-scrollbar::-webkit-scrollbar-track-piece {
   background-color: rgb(67 56 202 / 0.4);
}
